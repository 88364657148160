import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const NotificationList = Loadable(lazy(() => import('app/views/notification/Notification')));
const NotificationCreate = Loadable(
  lazy(() => import('app/views/notification/NotificationCreate'))
); 

const notificationRoutes = [
  {
    path: '/notifications',
    element: <NotificationList />,
    auth: authRoles.notification
  },
  {
    path: '/notifications/create',
    element: <NotificationCreate />,
    auth: authRoles.notification
  }
];

export default notificationRoutes;
