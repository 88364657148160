import { IconButton, Tooltip, styled } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import { capitalizeFirstLetter } from '../../utils/utils';
import { MatxLoading } from '..';

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}));

function Datatable({ title, data, columns, options, isLoading, addHandler = null }) {
  const customSearch = (searchQuery, currentRow) => {
    for (const key in currentRow) {
      if (
        currentRow[key] &&
        currentRow[key].toString().toLowerCase().includes(searchQuery.toLowerCase())
      )
        return true;
    }
    return false;
  };
  options = {
    ...options,
    customSearch: customSearch,
    viewColumns: false,
    rowHover: true,
    customToolbar: () => {
      if (addHandler) {
        return (
          <Tooltip title={`add ${title}`}>
            <IconButton onClick={addHandler}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        );
      }
    },
    selectableRowsHideCheckboxes: true,
    textLabels: {
      body: {
        noMatch: isLoading ? <MatxLoading /> : 'Sorry, there is no matching data to display'
      }
    }
  };
  return (
    <Container>
      <MUIDataTable
        title={capitalizeFirstLetter(title) + ' List'}
        data={data}
        columns={columns}
        options={options}
      />
    </Container>
  );
}

export default Datatable;
