import { Box } from '@mui/material'
import React from 'react'
import AvatarImage from '../AvatarImage'
import { Span, Title } from '../Typography'

const NameCell = ({ valueData, key }) => {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
            <AvatarImage
                id={key}
                alt={valueData.name}
                src={valueData.image}
                sx={{ width: 40, height: 40 }}
            />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Title>{valueData.name}</Title>
                <Span>{valueData.email}</Span>
            </Box>
        </Box>
    )
}


export default NameCell
