import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const CategoryList = Loadable(lazy(() => import('app/views/categories/Category')));
const CategoryCreate = Loadable(lazy(() => import('app/views/categories/CategoryCreate')));
const CategoryUpdate = Loadable(lazy(() => import('app/views/categories/CategoryUpdate')));

const categoryRoutes = [
  {
    path: '/categories',
    element: <CategoryList />,
    auth: authRoles.admin
  },
  {
    path: '/categories/create',
    element: <CategoryCreate />,
    auth: authRoles.admin
  },
  {
    path: '/categories/edit/:categoryId',
    element: <CategoryUpdate />,
    auth: authRoles.admin
  }
];

export default categoryRoutes;
