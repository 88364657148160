import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const BannerList = Loadable(lazy(() => import('app/views/banners/Banner')));
const BannerCreate = Loadable(lazy(() => import('app/views/banners/BannerCreate')));
const BannerUpdate = Loadable(lazy(() => import('app/views/banners/BannerUpdate')));

const bannerRoutes = [
  {
    path: '/banners',
    element: <BannerList />,
    auth: authRoles.admin
  },
  {
    path: '/banners/create',
    element: <BannerCreate />,
    auth: authRoles.admin
  },
  {
    path: '/banners/edit/:bannerId',
    element: <BannerUpdate />,
    auth: authRoles.admin
  }
];

export default bannerRoutes;
