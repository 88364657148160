import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const TypeList = Loadable(lazy(() => import('app/views/types/Type')));
const TypeCreate = Loadable(lazy(() => import('app/views/types/TypeCreate')));
const TypeUpdate = Loadable(lazy(() => import('app/views/types/TypeUpdate')));

const typeRoutes = [
  {
    path: '/types',
    element: <TypeList />,
    auth: authRoles.admin
  },
  {
    path: '/types/create',
    element: <TypeCreate />,
    auth: authRoles.admin
  },
  {
    path: '/types/edit/:typeId',
    element: <TypeUpdate />,
    auth: authRoles.admin
  }
];

export default typeRoutes;
