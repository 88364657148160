import VENDOR_ACTION_TYPES from './vendor.types';

export const VENDOR_INITIAL_STATE = {
  vendorLists: []
};

export const VendorReducer = (state = VENDOR_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case VENDOR_ACTION_TYPES.LOAD_VENDORS: {
      return { ...state, vendorLists: payload };
    }
    default:
      return state;
  }
};
