import { createSelector } from 'reselect';

const selectUsersFavouriteVendorReducer = (state) => {
  console.log(state.usersFavouriteVendor);
  return state.usersFavouriteVendor.usersFavouriteVendorLists;
};

export const selectUsersFavouriteVendor = createSelector(
  [selectUsersFavouriteVendorReducer],
  (state) => {
    return state;
  }
);
