import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const DealList = Loadable(lazy(() => import('app/views/deals/Deal')));
const DealView = Loadable(lazy(() => import('app/views/deals/DealView')));

const dealRoutes = [
  {
    path: '/deals',
    element: <DealList />,
    auth: authRoles.admin
  },
  {
      path: '/deals/view/:dealId',
      element: <DealView />,
      auth: authRoles.admin
  }
];

export default dealRoutes;
