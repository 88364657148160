import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { AuthProvider } from './contexts/JWTAuthContext';
import { SettingsProvider } from './contexts/SettingsContext';
import routes from './routes';
import { AlertProvider } from './contexts/AlertContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { Store } from './store/Store';
import { Provider } from 'react-redux';

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store}>
      <SettingsProvider>
        <MatxTheme>
          <AuthProvider>
            <AlertProvider>
              <SnackbarProvider>
                <CssBaseline />
                {content}
              </SnackbarProvider>
            </AlertProvider>
          </AuthProvider>
        </MatxTheme>
      </SettingsProvider >
    </Provider>
  );
};

export default App;
