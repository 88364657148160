import axiosClient from '../axios';

export const usersFavouriteVendorList = () => axiosClient.get('user-favourites');
export const usersFavouriteVendorCreate = (data) => axiosClient.post('user-favourites', data);
export const usersFavouriteVendorUpdate = (id, data) =>
  axiosClient.put(`user-favourites/${id}`, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
export const usersFavouriteVendorDelete = (id) => axiosClient.delete(`user-favourites/${id}`);
