import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import AuthGuard from './auth/AuthGuard';
import { authRoles } from './auth/authRoles';
import Loadable from './components/Loadable';
import MatxLayout from './components/MatxLayout/MatxLayout';
import adminRoutes from './views/admins/AdminRoutes';
import categoryRoutes from './views/categories/CategoryRoutes';
import vendorRoutes from './views/vendors/VendorRoutes';
import homeScreenGridRoutes from './views/homeScreenGrids/HomeScreenGridRoutes';
import bannerRoutes from './views/banners/BannerRoutes';
import typeRoutes from './views/types/TypeRoutes';
import userRoutes from './views/users/UserRoutes';
import UsersFavouriteVendor from './views/usersFavouriteVendors/UsersFavouriteVendor';
import usersFavouriteVendorRoutes from './views/usersFavouriteVendors/UsersFavouriteVendorRoutes';
import dealRoutes from './views/deals/DealRoutes';
import notificationRoutes from './views/notification/NotificationRoutes';

// session pages
const NotFound = Loadable(lazy(() => import('app/views/sessions/NotFound')));
const JwtLogin = Loadable(lazy(() => import('app/views/sessions/JwtLogin')));

// dashboard page
const Analytics = Loadable(lazy(() => import('app/views/dashboard/Analytics')));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // dashboard route
      {
        path: '/dashboard/default',
        element: <Analytics />,
        auth: authRoles.admin
      },
      ...adminRoutes,
      ...categoryRoutes,
      ...typeRoutes,
      ...vendorRoutes,
      ...homeScreenGridRoutes,
      ...bannerRoutes,
      ...userRoutes,
      ...usersFavouriteVendorRoutes,
      ...dealRoutes,
      ...notificationRoutes
    ]
  },

  // session pages route
  { path: '/session/404', element: <NotFound /> },
  { path: '/session/signin', element: <JwtLogin /> },

  { path: '/', element: <Navigate to="dashboard/default" /> },
  { path: '*', element: <NotFound /> }
];

export default routes;
