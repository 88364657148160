
const AppLogo = ({ className }) => {
  return (
    <svg width="210" height="48" viewBox="0 0 178 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M163.088 19.2059H163.265L163.335 19.0433L165.674 13.6284H172.709L174.623 19.0265L174.686 19.2059H174.877H177.032H177.414L177.285 18.8458L170.897 0.972304L170.798 0.696528L170.531 0.817717L170.253 0.94448L170.157 0.987752L170.116 1.08392L162.536 18.8306L162.376 19.2059H162.784H163.088ZM169.553 4.6638L172.442 12.836H166.008L169.553 4.6638Z" fill="white" stroke="#F5F5F5" stroke-width="0.538841"/>
<path d="M138.996 19.1426H139.265V18.8732V9.8129H146.966V18.8732V19.1426H147.235H149.34H149.609V18.8732V1.12646V0.857044H149.34H147.235H146.966V1.12646V9.02054H139.265V1.12646V0.857044H138.996H136.892H136.622V1.12646V18.8732V19.1426H136.892H138.996Z" fill="white" stroke="#F5F5F5" stroke-width="0.538841"/>
<path d="M84.2971 19.1426H84.5665V18.8732V9.8129H92.2673V18.8732V19.1426H92.5367H94.6409H94.9103V18.8732V1.12646V0.857044H94.6409H92.5367H92.2673V1.12646V9.02054H84.5665V1.12646V0.857044H84.2971H82.1929H81.9235V1.12646V18.8732V19.1426H82.1929H84.2971Z" fill="white" stroke="#F5F5F5" stroke-width="0.538841"/>
<path d="M67.613 2.45535L67.8611 2.55078L67.9598 2.30395L68.0612 2.05042L68.1674 1.78512L67.8963 1.69477C66.1472 1.11174 64.4198 0.818958 62.7152 0.818958C61.0221 0.818958 59.702 1.21583 58.8051 2.05715C57.9163 2.87391 57.4767 3.94564 57.4767 5.24618C57.4767 6.88998 58.1631 8.17481 59.5234 9.07036C60.1108 9.46757 60.7569 9.82914 61.4611 10.1555L61.4611 10.1555L61.4661 10.1577C62.16 10.4624 62.8455 10.7586 63.5225 11.0463L63.5247 11.0472C64.2075 11.3303 64.8298 11.6375 65.3924 11.9684C66.6051 12.6866 67.1867 13.66 67.1867 14.9055C67.1867 16.0539 66.7379 16.9045 65.8386 17.4939C64.9268 18.0856 63.814 18.3882 62.4871 18.3882C61.585 18.3882 60.6995 18.2572 59.8297 17.9949C58.9737 17.7148 58.2868 17.3647 57.7608 16.9502L57.5248 16.7643L57.3656 17.019L57.2388 17.2219L57.1034 17.4386L57.3126 17.5853C58.8352 18.6528 60.6661 19.1806 62.7913 19.1806C63.9263 19.1806 64.9258 19.0517 65.7857 18.7885L65.7857 18.7885L65.7889 18.7875C66.6884 18.4996 67.4504 17.9597 68.0726 17.1774C68.7061 16.3811 69.0185 15.4245 69.0185 14.3224C69.0185 13.2308 68.8371 12.3137 68.4559 11.587C68.0829 10.8593 67.5896 10.2823 66.9733 9.86535C66.384 9.46671 65.7357 9.11255 65.0292 8.80236C64.3308 8.49574 63.632 8.22297 62.9329 7.98413C62.267 7.73429 61.6525 7.46843 61.089 7.18679C59.8757 6.54748 59.3086 5.67502 59.3086 4.56167C59.3086 3.62576 59.6541 2.91357 60.3424 2.3935C61.0402 1.86626 62.0317 1.58597 63.3491 1.58597C64.679 1.58597 66.0997 1.87331 67.613 2.45535Z" fill="white" stroke="#F5F5F5" stroke-width="0.538841"/>
<path d="M30.0801 19.2059H30.2572L30.3275 19.0433L32.6657 13.6284H39.7012L41.6151 19.0265L41.6787 19.2059H41.869H44.024H44.4064L44.2777 18.8458L37.8888 0.972304L37.7903 0.696529L37.5237 0.817717L37.2448 0.944479L37.1496 0.987752L37.1085 1.08392L29.5281 18.8306L29.3678 19.2059H29.7759H30.0801ZM36.5448 4.6638L39.4344 12.836H33.0005L36.5448 4.6638Z" fill="white" stroke="#F5F5F5" stroke-width="0.538841"/>
<path d="M16.3323 19.5864C14.1109 19.6342 11.8935 19.5558 9.67399 19.5864C9.79105 19.5661 9.91023 19.5472 10.028 19.5256C10.3443 19.4681 10.6496 19.3508 10.931 19.1786C11.5398 18.8072 11.7121 17.9424 11.2541 17.2993C11.0932 17.0795 10.9191 16.8723 10.7329 16.6793C10.4019 16.3263 10.083 15.9588 9.76226 15.594C9.24928 15.0108 8.78758 14.3769 8.31348 13.7548C7.95339 13.2814 7.60477 12.7995 7.251 12.3216C6.8735 11.8187 6.54261 11.274 6.26345 10.6957C6.20211 10.5663 6.20118 10.5642 6.11174 10.6736C5.72075 11.1497 5.31009 11.6061 4.95936 12.1222C4.85064 12.279 4.79282 12.4729 4.79547 12.6717C4.79899 13.7809 4.79313 14.8898 4.79945 15.9988C4.8046 16.9013 5.01321 17.733 5.53883 18.4358C5.97571 19.0198 6.54815 19.3465 7.20511 19.5006C7.27371 19.5169 7.34325 19.5269 7.41279 19.539C7.48067 19.5472 7.54806 19.56 7.61461 19.5771C7.22993 19.5771 6.85908 19.484 6.49079 19.3772C5.66245 19.1381 4.95562 18.667 4.39067 17.9482C3.93529 17.3679 3.64146 16.7016 3.54781 15.9191C3.44877 15.0713 3.59018 14.2793 3.90509 13.5176C4.1158 13.0076 4.40425 12.5494 4.6852 12.0851C4.96615 11.6208 5.32952 11.2302 5.66245 10.8141C6.12672 10.2354 6.6418 9.70936 7.14518 9.17434C7.33482 8.97259 7.5343 8.78295 7.72745 8.58567C7.7712 8.54178 7.81046 8.49255 7.84452 8.4389C7.89649 8.35551 7.93208 8.36262 7.9782 8.44679C8.17019 8.7975 8.36413 9.14708 8.56001 9.49551C8.84776 10.0066 9.14112 10.5142 9.4223 11.029C9.91397 11.931 10.4874 12.7687 11.024 13.6352C11.1518 13.8417 11.3187 14.0087 11.4688 14.1928C11.9179 14.7465 12.3775 15.2889 12.8305 15.8384C13.2571 16.3557 13.6734 16.8834 14.1072 17.3932C14.4385 17.7827 14.8075 18.1281 15.1713 18.4792C15.5539 18.8499 15.9414 19.2144 16.3323 19.5864Z" fill="#F5F5F5"/>
<path d="M5.39394 19.3933C4.83962 19.1639 4.32477 18.8286 3.87211 18.4022C3.20724 17.7795 2.7306 16.9404 2.50785 16.0007C2.277 15.0467 2.36105 14.101 2.65957 13.1725C3.00514 12.0972 3.54223 11.1569 4.21043 10.3012C4.81635 9.52396 5.49813 8.83638 6.19419 8.16643C6.81299 7.57065 7.43624 6.98066 8.0356 6.35964C8.84124 5.52502 9.66864 4.71671 10.4373 3.83817C10.7651 3.46282 11.0846 3.07537 11.2883 2.59244C11.4522 2.20314 11.5178 1.79202 11.4478 1.3638C11.3778 0.935573 11.1303 0.674642 10.7704 0.539441C10.6735 0.503142 10.5745 0.473419 10.4577 0.434227C10.5248 0.387407 10.5721 0.402925 10.6169 0.401873C10.8934 0.395824 11.1696 0.411869 11.4464 0.409764C12.7495 0.400821 14.0529 0.403978 15.3563 0.404767C15.4031 0.404767 15.4781 0.374518 15.4921 0.43712C15.5047 0.493673 15.4305 0.518662 15.3879 0.542335C14.8913 0.831674 14.4701 1.23044 14.0613 1.65445C13.5463 2.18894 13.0078 2.69397 12.4896 3.22556C12.0092 3.71902 11.5122 4.19169 11.0467 4.70383C10.6457 5.1452 10.2467 5.58947 9.8316 6.01401C8.99319 6.87177 8.16929 7.74847 7.29506 8.56125C6.92256 8.9074 6.54023 9.24514 6.1963 9.62417C5.34524 10.5648 4.50496 11.5193 3.89904 12.693C3.51905 13.4295 3.23973 14.2063 3.17137 15.0603C3.11143 15.8095 3.24161 16.5262 3.52256 17.2083C3.88897 18.1026 4.48927 18.7568 5.20992 19.2879C5.26934 19.3271 5.33079 19.3623 5.39394 19.3933Z" fill="#F5F5F5"/>
<path d="M5.31047 2.50611C5.31857 2.20627 5.42337 1.9526 5.55655 1.69999L5.55795 1.69951L5.70629 1.51127C6.08682 1.07687 6.63036 0.938292 7.1531 0.935276L7.1547 0.399404L4.89009 0.400681L0.969393 0.397461L0.96875 0.934609C1.49301 0.936848 1.99856 1.05743 2.37898 1.49199C2.57764 1.71891 2.71981 2.038 2.75775 2.35354C2.77159 2.46868 2.77052 2.59038 2.76948 2.70956C2.7691 2.75223 2.76873 2.79459 2.76905 2.8362L2.77341 3.70075L2.77686 12.1884C2.88961 11.8329 3.18045 11.177 3.69489 10.3831L3.69869 3.1076L3.71134 3.11347C3.87744 3.37098 4.00375 3.67638 4.1067 3.9713C4.25339 4.39153 4.3269 4.86784 4.35852 5.3157C4.37983 5.61743 4.37598 5.92579 4.37216 6.23171C4.37076 6.34329 4.36937 6.45454 4.36921 6.56503L4.36669 8.76593L4.36862 9.69323C4.64313 9.34438 4.95595 8.98324 5.30995 8.62011L5.30785 3.58933L5.31047 2.50611Z" fill="#F5F5F5"/>
<path d="M123.448 19.5864C121.227 19.6342 119.009 19.5558 116.79 19.5864C116.907 19.5661 117.026 19.5472 117.144 19.5256C117.46 19.4681 117.765 19.3508 118.047 19.1786C118.655 18.8072 118.828 17.9424 118.37 17.2993C118.209 17.0795 118.035 16.8723 117.849 16.6793C117.518 16.3263 117.199 15.9588 116.878 15.594C116.365 15.0108 115.903 14.3769 115.429 13.7548C115.069 13.2814 114.72 12.7995 114.367 12.3216C113.989 11.8187 113.658 11.274 113.379 10.6957C113.318 10.5663 113.317 10.5642 113.227 10.6736C112.836 11.1497 112.426 11.6061 112.075 12.1222C111.966 12.279 111.909 12.4729 111.911 12.6717C111.915 13.7809 111.909 14.8898 111.915 15.9988C111.92 16.9013 112.129 17.733 112.655 18.4358C113.091 19.0198 113.664 19.3465 114.321 19.5006C114.389 19.5169 114.459 19.5269 114.529 19.539C114.596 19.5472 114.664 19.56 114.73 19.5771C114.346 19.5771 113.975 19.484 113.607 19.3772C112.778 19.1381 112.071 18.667 111.506 17.9482C111.051 17.3679 110.757 16.7016 110.664 15.9191C110.564 15.0713 110.706 14.2793 111.021 13.5176C111.232 13.0076 111.52 12.5494 111.801 12.0851C112.082 11.6208 112.445 11.2302 112.778 10.8141C113.242 10.2354 113.758 9.70936 114.261 9.17434C114.451 8.97259 114.65 8.78295 114.843 8.58567C114.887 8.54178 114.926 8.49255 114.96 8.4389C115.012 8.35551 115.048 8.36262 115.094 8.44679C115.286 8.7975 115.48 9.14708 115.676 9.49551C115.963 10.0066 116.257 10.5142 116.538 11.029C117.03 11.931 117.603 12.7687 118.14 13.6352C118.268 13.8417 118.434 14.0087 118.585 14.1928C119.034 14.7465 119.493 15.2889 119.946 15.8384C120.373 16.3557 120.789 16.8834 121.223 17.3932C121.554 17.7827 121.923 18.1281 122.287 18.4792C122.67 18.8499 123.057 19.2144 123.448 19.5864Z" fill="#F5F5F5"/>
<path d="M112.51 19.3933C111.955 19.1639 111.44 18.8286 110.988 18.4022C110.323 17.7795 109.846 16.9404 109.624 16.0007C109.393 15.0467 109.477 14.101 109.775 13.1725C110.121 12.0972 110.658 11.1569 111.326 10.3012C111.932 9.52396 112.614 8.83638 113.31 8.16643C113.929 7.57065 114.552 6.98066 115.151 6.35964C115.957 5.52502 116.784 4.71671 117.553 3.83817C117.881 3.46282 118.2 3.07537 118.404 2.59244C118.568 2.20314 118.634 1.79202 118.564 1.3638C118.493 0.935573 118.246 0.674642 117.886 0.539441C117.789 0.503142 117.69 0.473419 117.573 0.434227C117.641 0.387407 117.688 0.402925 117.733 0.401873C118.009 0.395824 118.285 0.411869 118.562 0.409764C119.865 0.400821 121.169 0.403978 122.472 0.404767C122.519 0.404767 122.594 0.374518 122.608 0.43712C122.62 0.493673 122.546 0.518662 122.504 0.542335C122.007 0.831674 121.586 1.23044 121.177 1.65445C120.662 2.18894 120.123 2.69397 119.605 3.22556C119.125 3.71902 118.628 4.19169 118.162 4.70383C117.761 5.1452 117.362 5.58947 116.947 6.01401C116.109 6.87177 115.285 7.74847 114.411 8.56125C114.038 8.9074 113.656 9.24514 113.312 9.62417C112.461 10.5648 111.621 11.5193 111.015 12.693C110.635 13.4295 110.355 14.2063 110.287 15.0603C110.227 15.8095 110.357 16.5262 110.638 17.2083C111.005 18.1026 111.605 18.7568 112.326 19.2879C112.385 19.3271 112.447 19.3623 112.51 19.3933Z" fill="#F5F5F5"/>
<path d="M112.426 2.50611C112.434 2.20627 112.539 1.9526 112.672 1.69999L112.674 1.69951L112.822 1.51127C113.203 1.07687 113.746 0.938292 114.269 0.935276L114.27 0.399404L112.006 0.400681L108.085 0.397461L108.084 0.934609C108.609 0.936848 109.114 1.05743 109.495 1.49199C109.693 1.71891 109.836 2.038 109.873 2.35354C109.887 2.46868 109.886 2.59038 109.885 2.70956C109.885 2.75223 109.884 2.79459 109.885 2.8362L109.889 3.70075L109.893 12.1884C110.005 11.8329 110.296 11.177 110.811 10.3831L110.814 3.1076L110.827 3.11347C110.993 3.37098 111.119 3.67638 111.222 3.9713C111.369 4.39153 111.443 4.86784 111.474 5.3157C111.496 5.61743 111.492 5.92579 111.488 6.23171C111.486 6.34329 111.485 6.45454 111.485 6.56503L111.482 8.76593L111.484 9.69323C111.759 9.34438 112.072 8.98324 112.426 8.62011L112.424 3.58933L112.426 2.50611Z" fill="#F5F5F5"/>
</svg>

  );
};

export default AppLogo;
