import USERS_FAVOURITE_VENDOR_ACTION_TYPES from './usersFavouriteVendor.types';

export const USERS_FAVOURITE_VENDOR_INITIAL_STATE = {
  usersFavouriteVendorLists: []
};

export const UsersFavouriteVendorReducer = (
  state = USERS_FAVOURITE_VENDOR_INITIAL_STATE,
  action = {}
) => {
  const { type, payload } = action;
  switch (type) {
    case USERS_FAVOURITE_VENDOR_ACTION_TYPES.LOAD_USERS_FAVOURITE_VENDORS: {
      console.log(payload);
      return { ...state, usersFavouriteVendorLists: payload };
    }
    default:
      return state;
  }
};
