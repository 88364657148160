import { Box, Card, Grid, Icon, IconButton, styled, Tooltip } from '@mui/material';
import { Title } from 'app/components/Typography';

const StyledCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px !important',
  background: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: { padding: '16px !important' }
}));

const ContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  '& small': { color: theme.palette.text.secondary },
  '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.main }
}));

const Heading = styled('h6')(({ theme }) => ({
  margin: 0,
  marginTop: '4px',
  fontSize: '14px',
  fontWeight: '500',
  color: theme.palette.primary.main
}));

const AnalyticsCards = ({ currentUser }) => {
  const cardList = [
    { name: 'Upcoming Deals', amount: currentUser.upcoming_deals, icon: 'upcoming_icon' },
    {
      name: 'Completed Deals',
      amount: currentUser.completed_deals,
      icon: 'library_add_check_icon'
    },
    { name: 'Canceled Deals', amount: currentUser.canceled_delas, icon: 'cancel_icon' }
  ];

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      spacing={3}
      sx={{ marginInline: 2 }}
    >
      {cardList.map((item, index) => (
        <Grid item xs={12} md={4} key={index}>
          <StyledCard elevation={6}>
            <ContentBox>
              <Icon className="icon">{item.icon}</Icon>
              <Box ml="12px">
                <Title>{item.name}</Title>
                <Heading>{item.amount}</Heading>
              </Box>
            </ContentBox>
          </StyledCard>
        </Grid>
      ))}
    </Grid>
  );
};

export default AnalyticsCards;
