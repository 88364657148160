import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import { apiVersion1, baseUrl } from 'app/utils/constant';
import { useNavigate } from 'react-router-dom';
import { setCategoryList } from 'app/store/category/category.action';
import { useDispatch } from 'react-redux';
import { setTypeList } from 'app/store/type/type.action';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

// const isValidToken = (accessToken) => {
//   if (!accessToken) return false;

//   const decodedToken = jwtDecode(accessToken);
//   const currentTime = Date.now() / 1000;
//   return decodedToken.exp > currentTime;
// };

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    console.log('sadsadsa');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    case 'REGISTER': {
      const { user } = action.payload;

      return { ...state, isAuthenticated: true, user };
    }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  logout: () => {},
  register: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();
  const dispatchState = useDispatch();

  const login = async (data) => {
    setSession(data.token);
    dispatch({ type: 'LOGIN', payload: { user: data.data } });
  };

  const register = async (email, username, password) => {
    const response = await axios.post('/api/auth/register', { email, username, password });
    const { user } = response.data;

    dispatch({ type: 'REGISTER', payload: { user } });
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const { data } = await axios.get(baseUrl + apiVersion1 + '/admin/profile', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setSession(token);
        dispatch({ type: 'INIT', payload: { isAuthenticated: true, user: data.result } });
        axios
          .get(baseUrl + apiVersion1 + '/admin/get-data', {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`
            }
          })
          .then((res) => {
            console.log('res');
            console.log(res.data.result.categories);
            dispatchState(setCategoryList(res.data.result.categories));
            dispatchState(setTypeList(res.data.result.types));
          });
      } catch (err) {
        if (err.response && err.response.status === 401) {
          dispatch({ type: 'INIT', payload: { isAuthenticated: false, user: null } });
          navigate('/session/signin');
        }
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', login, logout, register }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
