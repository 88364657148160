import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import UsersFavouriteVendor from './UsersFavouriteVendor';

const usersFavouriteVendorRoutes = [
  {
    path: '/users-favourite-vendors',
    element: <UsersFavouriteVendor />,
    auth: authRoles.admin
  }
];

export default usersFavouriteVendorRoutes;
