import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';

const InteractionsView = ({ currentUser }) => {
  return (
    <Box sx={{ margin: 4 }}>
      <SimpleCard title="Details">
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <WorkIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Total Deals" secondary={currentUser.deals.length} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <BeachAccessIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Total Favourite"
              secondary={currentUser.favourite_vendor.length}
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <BeachAccessIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Address" secondary={currentUser.address.length} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <BeachAccessIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Like" secondary={currentUser.likes.length} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <BeachAccessIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Total Deal Amount" secondary={currentUser.deals_amount} />
          </ListItem>
        </List>
      </SimpleCard>
    </Box>
  );
};

export default InteractionsView;
