import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const VendorList = Loadable(lazy(() => import('app/views/vendors/Vendor')));
const VendorCreate = Loadable(lazy(() => import('app/views/vendors/VendorCreate')));
const VendorUpdate = Loadable(lazy(() => import('app/views/vendors/VendorUpdate')));
const VendorView = Loadable(lazy(() => import('app/views/vendors/VendorView')));

const vendorRoutes = [
  {
    path: '/vendors',
    element: <VendorList />,
    auth: authRoles.admin
  },
  {
    path: '/vendors/create',
    element: <VendorCreate />,
    auth: authRoles.admin
  },
  {
    path: '/vendors/edit/:vendorId',
    element: <VendorUpdate />,
    auth: authRoles.admin
  },
  {
    path: '/vendors/view/:vendorId',
    element: <VendorView />,
    auth: authRoles.admin
  }
];

export default vendorRoutes;
