import Datatable from 'app/components/tables/Datatable';
import NameCell from 'app/components/tables/NameCell';
import useAlert from 'app/hooks/useAlert';
import { userList } from 'app/services/api/user';
import { usersFavouriteVendorList } from 'app/services/api/usersFavouriteVendor';
import { setUserList } from 'app/store/user/user.action';
import { setUsersFavouriteVendorList } from 'app/store/usersFavouriteVendor/usersFavouriteVendor.action';
import { selectUsersFavouriteVendor } from 'app/store/usersFavouriteVendor/usersFavouriteVendor.selector';
import { isObjectEmpty } from 'app/utils/utils';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const UsersFavouriteVendor = () => {
  const title = 'Users Favourite Vendors';
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { updateState } = useAlert();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const setUser = () => {
    usersFavouriteVendorList()
      .then((res) => {
        dispatch(setUsersFavouriteVendorList(res.data.result.data));
      })
      .catch((error) => {
        openSnackbar('error', error.data.message);
      });
  };
  useEffect(() => {
    setLoading(true);
    setUser();
    setLoading(false);
  }, []);

  const usersFavouriteVendor = useSelector(selectUsersFavouriteVendor);
  console.log(usersFavouriteVendor);
  const columns = [
    {
      name: 'id',
      label: 'id',
      options: {
        display: false,
        download: false,
        filter: false,
        sort: false
      }
    },
    {
      name: 'name',
      label: 'User Name',
      options: {
        display: false,
        filter: true
      }
    },
    {
      name: 'email',
      label: 'Email Name',
      options: {
        display: false,
        filter: true
      }
    },
    {
      name: 'vendor_name',
      label: 'Vendor Name',
      options: {
        display: false,
        filter: true
      }
    },
    {
      name: 'user_name',
      label: 'User Name',
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return <NameCell valueData={valueData} tableMeta={tableMeta} />;
        },
        filter: false
      }
    },

    {
      name: 'vendor_name',
      label: 'Vendor Name',
      options: {
        customBodyRender: (valueData, tableMeta) => {
          return <NameCell valueData={valueData} tableMeta={tableMeta} />;
        },
        filter: false
      }
    },
    {
      label: 'Create Date',
      options: {
        filter: false
      }
    }
  ];

  const data = isObjectEmpty(usersFavouriteVendor)
    ? []
    : usersFavouriteVendor.map((item, index) => {
        return [
          item.id,
          item.user?.name,
          item.user?.email,
          item.vendor?.name,
          { name: item.user?.name, image: item.user?.profile_image, email: item.user?.email },
          { name: item.vendor?.name, image: item.vendor?.profile_image },
          item.date
        ];
      });

  const options = {
    filterType: 'textField',
    fixedHeader: true,
    tableBodyHeight: '400px'
  };
  return (
    <Datatable title={title} data={data} columns={columns} options={options} isLoading={loading} />
  );
};

export default UsersFavouriteVendor;
