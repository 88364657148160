import { apiVersion1, baseUrl } from 'app/utils/constant';
import axios from 'axios';
const axiosClient = axios.create({
  baseURL: baseUrl + apiVersion1 + '/admin/',
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    Accept: 'application/json',
    'Content-Type': 'multipart/form-data; boundary=<calculated when request is sent>'
  }
});

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let res = error.response;
    if (res.status == 401) {
      return (window.location.href = '/session/signin');
    }
    console.error('Looks like there was a problem. Status Code: ' + res.status);
    return Promise.reject(error);
  }
);

export default axiosClient;
