import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { SimpleCard } from 'app/components';
import React from 'react';

const DetailView = ({ currentUser }) => {
  return (
    <Box sx={{ margin: 4 }}>
      <SimpleCard title="Details">
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <WorkIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Name" secondary={currentUser.name} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <BeachAccessIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="User Name" secondary={currentUser.user_name} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <BeachAccessIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Email" secondary={currentUser.email} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{/* <BeachAccessIcon /> */}</Avatar>
            </ListItemAvatar>
            <ListItemText primary="Active" secondary={currentUser.is_active ? 'Yes' : 'No'} />
          </ListItem>
        </List>
      </SimpleCard>
    </Box>
  );
};

export default DetailView;
