import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';

const HomeScreenGridList = Loadable(lazy(() => import('app/views/homeScreenGrids/HomeScreenGrid')));
const HomeScreenGridCreate = Loadable(lazy(() => import('app/views/homeScreenGrids/HomeScreenGridCreate')));
const HomeScreenGridUpdate = Loadable(lazy(() => import('app/views/homeScreenGrids/HomeScreenGridUpdate')));

const homeScreenGridRoutes = [
  {
    path: '/home-screen-grids',
    element: <HomeScreenGridList />,
    auth: authRoles.admin
  },
  {
    path: '/home-screen-grids/create',
    element: <HomeScreenGridCreate />,
    auth: authRoles.admin
  },
  {
    path: '/home-screen-grids/edit/:homeScreenGridId',
    element: <HomeScreenGridUpdate />,
    auth: authRoles.admin
  }
];

export default homeScreenGridRoutes;

