import { combineReducers } from 'redux';
import { AdminReducer } from './admin/admin.reducer';
import { CategoryReducer } from './category/category.reducer';
import { VendorReducer } from './vendor/vendor.reducer';
import { HomeScreenGridReducer } from './homeScreenGrid/homeScreenGrid.reducer';
import { BannerReducer } from './banner/banner.reducer';
import { TypeReducer } from './type/type.reducer';
import { UserReducer } from './user/user.reducer';
import { UsersFavouriteVendorReducer } from './usersFavouriteVendor/usersFavouriteVendor.reducer';
import { DealReducer } from './deal/deal.reducer';
import { NotificationReducer } from './notification/notification.reducer';

// reducer import

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  admin: AdminReducer,
  category: CategoryReducer,
  type: TypeReducer,
  vendor: VendorReducer,
  homeScreenGrid: HomeScreenGridReducer,
  banner: BannerReducer,
  user: UserReducer,
  usersFavouriteVendor: UsersFavouriteVendorReducer,
  deal: DealReducer,
  notification: NotificationReducer
});

export default reducer;
