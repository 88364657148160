import DEAL_ACTION_TYPES from './deal.types';

export const DEAL_INITIAL_STATE = {
  dealLists: []
};

export const DealReducer = (state = DEAL_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case DEAL_ACTION_TYPES.LOAD_DEALS: {
      return { ...state, dealLists: payload };
    }
    default:
      return state;
  }
};
