import styled from '@emotion/styled';
import { Avatar, Box, Card, Grid } from '@mui/material';
import { Breadcrumb, MatxLoading } from 'app/components';
import { H2, H3, Title } from 'app/components/Typography';
import { capitalizeFirstLetter } from 'app/utils/utils';
import { useParams } from 'react-router-dom';
import AnalyticsCards from './AnalyticsCards';
import MenuCards from './MenuCards';
import DenseTable from 'app/components/tables/DenseTable';
import { useState, useEffect, useCallback } from 'react';
import DetailView from './DetailView';
import { avatarImage } from 'app/utils/constant';
import { userDetail } from 'app/services/api/user';
import { useSnackbar } from 'notistack';
import InteractionsView from './InteractionsView';
import NameCell from 'app/components/tables/NameCell';

const UserView = () => {
  const [active, setActive] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [currentUser, setCurrentUser] = useState(null);
  const { openSnackbar } = useSnackbar();
  const { userId } = useParams();

  const getUser = useCallback(() => {
    userDetail(userId)
      .then((res) => {
        setCurrentUser(res.data.result);
        setIsLoading(false);
      })
      .catch((error) => {
        openSnackbar('error', error.data.message);
      });
  }, [userId, openSnackbar]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const dealTableKey = ['Booking No', 'Vendor', 'Sub Total', 'Status'];

  const dealTableValue =
    !isLoading &&
    currentUser.deals.map((row, key) => {
      return {
        booking_id: <Title>{row.booking_id}</Title>,
        user_name: (
          <NameCell
            valueData={{
              name: row.vendor.name,
              email: row.vendor.user_name,
              image: row.vendor.profile_image
            }}
            key={key}
          />
        ),
        sub_total: row.sub_total,
        status: row.status[0]?.name
      };
    });

  const addressTableKey = ['Title', 'Address Line 1', 'Address Line 2', 'City', 'State'];

  const addressTableValue =
    currentUser &&
    currentUser.address &&
    currentUser.address.map((row) => ({
      title: row.title,
      address_line_1: row.address_line_1,
      address_line_2: row.address_line_2,
      city: row.city,
      state: row.state
    }));

  const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
    '& .breadcrumb': {
      marginBottom: '30px',
      [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
    }
  }));

  const renderLoading = () => <MatxLoading />;

  const renderUserView = () => (
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb
          routeSegments={[
            { name: capitalizeFirstLetter('users'), path: '/users' },
            { name: 'User View' }
          ]}
        />
      </Box>
      {currentUser && (
        <Card>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={3}
            sx={{ height: '350px', bgcolor: 'primary.main' }}
          >
            {/* Avatar and Stats Grid */}
            <Grid item xs={3}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 1
              }}
            >
              <Avatar
                // alt={currentUser.name}
                src={currentUser.profile_image != 'null' ? currentUser.profile_image : avatarImage}
                sx={{ width: 130, height: 130 }}
              />
              <H2 color="white">{currentUser.name}</H2>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  gap: 1,
                  width: '100%',
                  marginTop: 1
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Title color="rgba(255, 255, 255, 0.54)">DEALS AMOUNT</Title>
                  <H3 color="white">{currentUser.deals_amount}</H3>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    gap: 1
                  }}
                >
                  <Title color="rgba(255, 255, 255, 0.54)">FAVOURITED VENDOR</Title>
                  <H3 color="white">{currentUser.favourite_vendor?.length}</H3>
                </Box>
              </Box>
            </Box>
            </Grid>

            {/* Analytics Cards */}
            <Grid item xs={8} sx={{ display: 'flex', gap: 2, justifyContent: 'space-evenly' }}>
              <AnalyticsCards currentUser={currentUser} />
            </Grid>
          </Grid>
        </Card>
      )}
      <Grid container direction="row" gap={0}>
        {/* Menu Cards */}
        <MenuCards active={active} setActive={setActive} />

        {/* Table or Detail View */}
        <Grid item xs={8}>
          {active === 0 && <DenseTable tableKey={dealTableKey} tableValue={dealTableValue} />}
          {active === 1 && <DetailView currentUser={currentUser} />}
          {active === 2 && <DenseTable tableKey={addressTableKey} tableValue={addressTableValue} />}
          {active === 3 && <InteractionsView currentUser={currentUser} />}
        </Grid>
      </Grid>
    </Container>
  );

  if (isLoading) {
    return renderLoading();
  }

  return currentUser ? renderUserView() : null;
};

export default UserView;
