import HOME_SCREEN_GRID_ACTION_TYPES from './homeScreenGrid.types';

export const HOME_SCREEN_GRID_INITIAL_STATE = {
  homeScreenGridLists: [],
};

export const HomeScreenGridReducer = (state = HOME_SCREEN_GRID_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case HOME_SCREEN_GRID_ACTION_TYPES.LOAD_HOME_SCREEN_GRIDS: {
      return { ...state, homeScreenGridLists: payload };
    }
    default:
      return state;
  }
};
