import { Box, Grid, Icon, Paper, styled } from '@mui/material'
import { Title } from 'app/components/Typography';
import React from 'react'

const ContentBox = styled(Paper)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    height: 150,
    alignItems: 'center',
    justifyContent: 'center',
    '&.active': { backgroundColor: theme.palette.primary.main, color: 'white', '& .icon': { opacity: 0.8, fontSize: '44px', color: 'white' } },
    '& .icon': { opacity: 0.6, fontSize: '44px', color: theme.palette.primary.dark },
}));

const MenuCards = ({active, setActive}) => {
    
    const cardList = [
        { name: 'Deals', icon: 'upcoming_icon'},
        { name: 'Details', icon: 'library_add_check_icon'},
        { name: 'Addresses', icon: 'cancel_icon'},
        { name: 'Interactions', icon: 'cancel_icon'},
    ];

    return (
        <Grid container item xs={4} spacing={2} height="fit-content" marginTop={2}>
            {cardList.map((item, index) => (
                <Grid item xs={6} margin={0} key={index} onClick ={() => setActive(index)}>
                    <ContentBox className={active == index ? 'active' : ''}>
                        <Icon className="icon">{item.icon}</Icon>
                        <Title>{item.name}</Title>
                    </ContentBox>
                </Grid>
            ))}
        </Grid>

    )

}

export default MenuCards
