import USER_ACTION_TYPES from './user.types';

export const USER_INITIAL_STATE = {
  userLists: []
};

export const UserReducer = (state = USER_INITIAL_STATE, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPES.LOAD_USERS: {
      return { ...state, userLists: payload };
    }
    default:
      return state;
  }
};
