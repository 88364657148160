import { lazy } from 'react';
import { authRoles } from 'app/auth/authRoles';
import Loadable from 'app/components/Loadable';
import UserView from './UserView';

const UserList = Loadable(lazy(() => import('app/views/users/User')));

const userRoutes = [
  {
    path: '/users',
    element: <UserList />,
    auth: authRoles.admin
  },
  {
    path: '/users/view/:userId',
    element: <UserView />,
    auth: authRoles.admin
  }
];

export default userRoutes;
